import React from "react";


function Coming() {
    const width= 250;
    const style = {width: width};

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#f8f9fa',
            color: '#333',
        }}>

            <img src="img/logos/logo_pm_transparent_mini.png" style={{width: '60vw', paddingBottom: '5vh'}}/>

            <h1 style={{
                fontSize: '29px',
                textAlign: 'center',
                paddingBottom: '25vh',
            }}>
                Nous-vous donnons rendez-vous le 6 Janvier pour vous présenter notre
                offre. <br/>
                See you on January 6th to discover our offer.
            </h1>

            <div className="slider-services-wrapper">
                <div className="bandeau_coming"></div>
                <div className="slider-box-content box-up clearfix">
                    <div className="row no-gutters">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-xs-12">
                                    <div className="item">
                                        <div className="post-img shadow">
                                            <div className="img"><img src="img/image-1.png" alt=""
                                                                      style={style}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <div className="item">
                                        <div className="post-img shadow">
                                            <div className="img"><img src="img/image-2.png" alt=""
                                                                      style={style}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12">
                                    <div className="item">
                                        <div className="post-img shadow">
                                            <div className="img"><img src="img/image-3.png" alt=""
                                                                      style={style}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Coming;
