import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useContentId, useContentsId} from "../services/api/contents/use-contents";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Content() {
    const navigate = useNavigate();
  const { id } = useParams();
  const {data: content} = useContentId(id);
  const {data: contents} = useContentsId(id);

  return (
      <div className="App">
          {/*<div className="preloader-bg"></div>
          <div id="preloader">
              <div id="preloader-status">
                  <div className="preloader-position loader"><span></span></div>
              </div>
          </div>*/}
          <Nav/>

          <div className=" section-padding"/>

          <div id="products" data-scroll-index="3">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12 text-center">
                          <div className="section-title">{content?.title}</div>
                      </div>
                  </div>
                  <div className="row">
                      {contents?.sort((a, b) => a.order - b.order).map(((item, index) => (
                          <div key={index}
                               className={`col-md-6 single-item interior ${index % 2 ? 'interior' : 'exterior'}`}
                               style={{cursor: 'pointer'}}>
                              <div onClick={() => navigate('/contents/' + item.id + '/show')} className="savoye-project-wrap">
                                  <img src={item?.image?.url} alt=""/>
                                  <div className="savoye-project-content">
                                      {item.subtitle && (<p>{item.subtitle}</p>)}
                                      <h3 style={{textDecoration: 'underline'}}>{item.title}</h3>
                                  </div>
                              </div>
                          </div>
                      )))}
                  </div>
              </div>
          </div>
          <div className=" section-padding"/>
          <hr className="line-vr-section"/>

          <Footer/>
      </div>
  );
}

export default Content;
