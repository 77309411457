import React from "react";

function Nav () {
  return (
      <nav className="navbar navbar-expand-lg nav-scroll">
          <div className="container">
              <div className="logo-wrapper">
                  <a className="logo" href="/"> <img src="/img/logo-light.png" alt=""/> </a>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse"
                      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                      aria-label="Toggle navigation"><span className="icon-bar"><i className="ti-menu"></i></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                      <li className="nav-item"><a className="nav-link active" href="/" data-scroll-nav="0">Accueil</a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="1">Qui sommes-nous</a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="2">Nos services</a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="3">L'Architecte</a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="3">L'installateur</a></li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="5">Produits et matériaux</a>
                      </li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="6">Actualités</a></li>
                      <li className="nav-item"><a className="nav-link" href="/" data-scroll-nav="7">Contact</a></li>
                  </ul>
              </div>
          </div>
      </nav>
  )
}

export default Nav;
