import React, {useState} from 'react';
import {useContents} from "../services/api/contents/use-contents";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import {useMember} from "../services/api/members/use-member";
import {useEchantillon} from "../services/api/echantillons/use-echantillon";
import {useNavigate} from "react-router-dom";

function Home() {
    const navigate = useNavigate();
    const {data: contents} = useContents();
    // const [services, setServices] = useState([]);
    //
    // useEffect(() => {
    //   if (contents) {
    //     setServices(contents.filter(content => content.content_type === "services"));
    //   }
    // }, [contents]);
    const quiSommesNous = contents?.find(content => content.content_type === "qui-sommes-nous");
    const services = contents?.filter(content => content.content_type === "services" && content.content_id === null);
    const architecte = contents?.find(content => content.content_type === "architecte");
    const installateur = contents?.find(content => content.content_type === "installateur");
    const products = contents?.filter(content => content.content_type === "products" && content.content_id === null);
    const blog = contents?.filter(content => content.content_type === "blog" && content.content_id === null);

    const [formArchitecte, setFormArchitecte] = useState(false);
    const [formInstallateur, setFormInstallateur] = useState(false);
    const [name, setName] = useState('');
    const [firstname, setFirstname] = useState('');
    const [company, setCompany] = useState('');
    const [adresse, setAdresse] = useState('');
    const [codepostal, setCodepostal] = useState('');
    const [ville, setVille] = useState('');
    const [secteur, setSecteur] = useState('');
    const [region, setRegion] = useState('');
    const [siret, setSiret] = useState('');
    const [naf, setNaf] = useState('');
    const [projet, setProjet] = useState('');
    const { mutateAsync: createMember } = useMember();
    const { mutateAsync: createEchantillon } = useEchantillon();

    const handleSubmitInstallateur = async () => {
        try {
            const response = await createMember({ name, firstname, company, secteur, adresse, codepostal, ville, region, siret, naf });
            if (response) {
                setFirstname('');
                setCompany('');
                setAdresse('');
                setCodepostal('');
                setVille('');
                setSecteur('');
                setRegion('');
                setSiret('');
                setNaf('');
                setFormInstallateur(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmitArchitecte = async () => {
        try {
            const response = await createEchantillon({ name, firstname, company, secteur, adresse, codepostal, ville, region, projet });
            if (response) {
                setFirstname('');
                setCompany('');
                setAdresse('');
                setCodepostal('');
                setVille('');
                setSecteur('');
                setRegion('');
                setProjet('');
                setFormArchitecte(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onsubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div>
            {/*<div className="preloader-bg"></div>
            <div id="preloader">
                <div id="preloader-status">
                    <div className="preloader-position loader"><span></span></div>
                </div>
            </div>
            <div className="progress-wrap cursor-pointer">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
                </svg>
            </div>*/}
            <Nav/>
            <header id="home" className="header slider-fade" data-scroll-index="0">
                <div className="owl-carousel owl-theme">
                    <div className="text-left item bg-img" data-overlay-dark="0" data-background="">
                        <div className="v-middle caption">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5 mt-5">
                                        <div className="section-title"><img src="img/logo_pm_transparent.png"/></div>
                                        <h3>Votre développement commercial passe aussi par la prescription</h3>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="about-img">
                                            <div className="img">
                                                <img src="img/home.png" className="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container slider-services-wrapper">
                <div className="slider-box-content box-up clearfix">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <hr className="no-line"/>
                        </div>
                        {products?.sort((a, b) => a.order - b.order).map(((item, index) => (
                            <div className="col-md-2" key={index}>
                                <div className="slider-services-box" onClick={() => navigate('/contents/' + item.id)}>
                                    <div className="slider-services-box-info">
                                        <h5 className="slider-services-item">{item.title}</h5>
                                    </div>
                                </div>
                            </div>
                        )))}

                    </div>
                </div>
            </div>

            <div className={"section-padding"}/>

            <div className="slider-services-wrapper ">
                <div className="bandeau"></div>
                <div className="slider-box-content box-up clearfix">
                    <div className="row no-gutters">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="item">
                                        <div className="post-img shadow">
                                            <div className="img"><img src="img/image-1.png" alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="item">
                                        <div className="post-img shadow">
                                            <div className="img"><img src="img/image-2.png" alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="item">
                                        <div className="post-img shadow">
                                            <div className="img"><img src="img/image-3.png" alt=""/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="about" className="about section-padding" data-scroll-index="1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-title">{quiSommesNous?.title}</div>
                            <div dangerouslySetInnerHTML={{__html: quiSommesNous?.description}}/>
                        </div>
                        <div className="col-md-6">
                            <div className="about-img">
                                <div className="img"><img src={quiSommesNous?.image.url} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="services" className="services-feat " data-scroll-index="2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title">Nos Services</div>
                        </div>
                    </div>
                    <div className="services-feat section-padding">
                        <div className="container">
                            <div className="row">
                                {services?.sort((a, b) => a.order - b.order).map(((item, index) => (
                                    <div key={index} className="col-md-6">
                                        <div className="square-flip">
                                            {/*<div className="square bg-img" style={"background-image: url('"${process.env.REACT_APP_BASE_URL+services?.[0]?.image?.url}"');">*/}

                                            <div className="square bg-img"
                                                 style={{backgroundImage: `url("${item.image?.url}")`}}>
                                                <div
                                                    className="square-container d-flex align-items-end justify-content-end">
                                                    <div className="box-title text-vertical">
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="flip-overlay"></div>
                                            </div>
                                            <div className="square2">
                                                <div className="square-container2">
                                                    <div dangerouslySetInnerHTML={{__html: item.description}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="architecte" className="blog " data-scroll-index="3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="item">
                                <div className="post-img">
                                    <div className="img"><img src={architecte?.image.url} alt=""/></div>
                                </div>
                                <div className="cont">
                                    <h4>{architecte?.title}</h4>
                                    <div dangerouslySetInnerHTML={{__html: architecte?.description}}/>
                                    <div className="col-md-12">
                                        <button onClick={() => {
                                            setFormInstallateur(false);
                                            setFormArchitecte(true);
                                        }}
                                                className="btn float-btn flat-btn mt-15">Recevoir vos échantillons
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item">
                                <div className="post-img">
                                    <div className="img"><img src={installateur?.image.url} alt=""/></div>
                                </div>
                                <div className="cont">
                                    <h4>{installateur?.title}</h4>
                                    <div dangerouslySetInnerHTML={{__html: installateur?.description}}/>
                                    <div className="col-md-12">
                                        <button onClick={() => {
                                            setFormArchitecte(false);
                                            setFormInstallateur(true)
                                        }}
                                                className="btn float-btn flat-btn mt-15">Devenir membre du réseaux P&M
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(formArchitecte || formInstallateur) && (
                        <>
                            <div className="section-padding"/>
                        </>
                    )}
                    {formArchitecte && (<div className="row">
                        <div className="col-md-12">
                            <h6>Demander vos échantillons</h6>
                            <form onSubmit={onsubmit} className="row">
                                <div className="col-md-12">
                                    <input
                                        placeholder="Nom *"
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Prénom *"
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Entreprise *"
                                        type="text"
                                        id="company"
                                        name="company"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Adresse *"
                                        type="text"
                                        id="adresse"
                                        name="adresse"
                                        value={adresse}
                                        onChange={(e) => setAdresse(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Code postal *"
                                        type="text"
                                        id="codepostal"
                                        name="codepostal"
                                        value={codepostal}
                                        onChange={(e) => setCodepostal(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Ville *"
                                        type="text"
                                        id="ville"
                                        name="ville"
                                        value={ville}
                                        onChange={(e) => setVille(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Région *"
                                        type="text"
                                        id="region"
                                        name="region"
                                        value={region}
                                        onChange={(e) => setRegion(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Avez-vous un projet *"
                                        type="text"
                                        id="projet"
                                        name="projet"
                                        value={projet}
                                        onChange={(e) => setProjet(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <select id="secteur" value={secteur} onChange={(e) => setSecteur(e.target.value)}>
                                        <option value="">Secteur *</option>
                                        {products?.sort((a, b) => a.order - b.order).map(((item, index) => (
                                            <option value={item.id}>{item.title}</option>)))}
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" onClick={() => handleSubmitArchitecte()}>Envoyer</button>
                                </div>

                            </form>
                        </div>
                    </div>)}

                    {formInstallateur && (<div className="row">
                        <div className="col-md-12">
                            <h6>Devenez membre du réseau P&M</h6>
                            <form onSubmit={onsubmit} className="row">
                                <div className="col-md-12">
                                    <input
                                        placeholder="Nom *"
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Prénom *"
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        value={firstname}
                                        onChange={(e) => setFirstname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Entreprise *"
                                        type="text"
                                        id="company"
                                        name="company"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Adresse *"
                                        type="text"
                                        id="adresse"
                                        name="adresse"
                                        value={adresse}
                                        onChange={(e) => setAdresse(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-md-12">
                                    <input
                                        placeholder="Code postal *"
                                        type="text"
                                        id="codepostal"
                                        name="codepostal"
                                        value={codepostal}
                                        onChange={(e) => setCodepostal(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Ville *"
                                        type="text"
                                        id="ville"
                                        name="ville"
                                        value={ville}
                                        onChange={(e) => setVille(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="Région *"
                                        type="text"
                                        id="region"
                                        name="region"
                                        value={region}
                                        onChange={(e) => setRegion(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="SIRET *"
                                        type="text"
                                        id="siret"
                                        name="siret"
                                        value={siret}
                                        onChange={(e) => setSiret(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input
                                        placeholder="NAF"
                                        type="text"
                                        id="naf"
                                        name="naf"
                                        value={naf}
                                        onChange={(e) => setNaf(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="col-md-12">
                                    <select id="secteur" value={secteur} onChange={(e) => setSecteur(e.target.value)}>
                                        <option value="">Secteur *</option>
                                        {products?.sort((a, b) => a.order - b.order).map(((item, index) => (
                                            <option value={item.id}>{item.title}</option>)))}
                                    </select>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" onClick={() => handleSubmitInstallateur()}>Envoyer</button>
                                </div>

                            </form>
                        </div>
                    </div>)}


                </div>
            </div>

            <div className=" section-padding"/>

            <div id="products" className="" data-scroll-index="5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-title">Produits et matériaux</div>
                        </div>
                    </div>
                    <div className="row">
                        {products?.sort((a, b) => a.order - b.order).map(((item, index) => (
                            <div className={`col-md-4 single-item interior ${index % 2 ? 'interior' : 'exterior'}`}
                                 style={{cursor: 'pointer'}}>
                                <div onClick={() => navigate('/contents/' + item.id)} className="savoye-project-wrap">
                                    <img src={item.image?.url} alt=""/>
                                    <div className="savoye-project-content">
                                        {item.subtitle && (<p>{item.subtitle}</p>)}
                                        <h3 style={{textDecoration: 'underline'}}>{item.title}</h3>
                                    </div>
                                </div>
                            </div>

                        )))}
                    </div>
                </div>
            </div>

            {/*<div className=" section-padding"/>
        <div className=" section-padding"/>*/}

            <div className=" section-padding"/>

            <div id="blog" className="blog " data-scroll-index="6">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">Actualités</div>
                        </div>
                    </div>
                    <div className="row">
                        {blog?.sort((a, b) => a.order - b.order).map(((item, index) => (
                            <div className="col-md-4">
                                <div className="item">
                                    <div className="post-img">
                                        <a href={`/actualite/${item.id}`}>
                                            <div className="img"><img src={item.image.url} alt={`Blog ${item.id}`}/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="cont">
                                        <h4><a href={`/actualite/${item.id}`}>{item.title}</a></h4>
                                        <div className="info"><a
                                            href={`/actualite/${item.id}`}><span>{item.subtitle}</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))}
                    </div>
                </div>
            </div>
            <hr className="line-vr-section"/>
            <Footer/>
        </div>
    );
}

export default Home;
