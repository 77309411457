import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Content from "./pages/Content";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import ContentShow from "./pages/ContentShow";
import Contact from "./pages/Contact";
import Coming from "./pages/Coming";
import ScrollToTop from "./services/utils/ScrollToTop";


function App() {
  return (
          <Router>
              <ScrollToTop />
            <Routes>
              <Route path="/" element={<Coming />} />
              <Route path="/home" element={<Home />} />
              <Route path="/contents/:id" element={<Content />} />
              <Route path="/contents/:id/show" element={<ContentShow />} />
              <Route path="/actualite/:id" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Home />} />  {/* Route pour pages non trouvées */}
            </Routes>
          </Router>

  );
}

export default App;
