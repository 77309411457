import {useMutation} from "react-query";
import useApi from "../axios";

export const useEchantillon = () => {
    const { post } = useApi();

    return useMutation({
        mutationFn: async (data) => await post("/echantillons", data),
    });
};
