import React from "react";

function Footer () {
  return (
      <>

          <div id="contact" className="topbanner-footer" data-scroll-index="7">
              <div className="section-padding banner-img valign bg-img bg-fixed" data-overlay-light="4">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-12 mb-30 text-left caption">
                              <div className="section-title">Nous contacter</div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-md-4">
                              <h6>Phone</h6>
                              <h5 className="mb-30"><a href="tel:++33750095844">+33 (0) 7 50 09 58 44</a></h5>
                          </div>
                          <div className="col-md-4">
                              <h6>Email</h6>
                              <h5 className="mb-30"><a
                                  href="mailto:faz@prescription-box.com">faz@prescription-box.com</a></h5>
                              <p className="mb-30">5, Place du marché<br/>42300 Roanne</p>
                          </div>
                          <div className="col-md-3 offset-md-1">
                              <div className="vid-area">
                                  <a className="play-button gallery-masonry-item-img-link d-block" data-type="iframe"
                                     data-fancybox="iframe"
                                     href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.758017847153!2d-79.93420398486563!3d32.77215479154045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fe7a1ae84ff639%3A0xe5c782f71924a526!2s24%20King%20St%2C%20Charleston%2C%20SC%2029401%2C%20USA!5e0!3m2!1sen!2str!4v1631170502143!5m2!1sen!2str">
                                      <svg className="circle-fill">
                                          <circle cx="43" cy="43" r="39" stroke="#272727" stroke-width="1"></circle>
                                      </svg>
                                      <svg className="circle-track">
                                          <circle cx="43" cy="43" r="39" stroke="none" stroke-width="1"
                                                  fill="none"></circle>
                                      </svg>
                                      <span className="polygon"><i className="ti-location-pin"></i></span> </a>
                                  <div className="cont mt-20 mb-30">
                                      <h5>Notre localisation</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <footer className="main-footer dark">
              <div className="container">
                  <div className="row">
                      <div className="col-md-3 mb-30">
                          <div className="item abot">
                              <div className="logo mb-15"><img src="img/logo-light.png" alt=""/></div>
                              {/*<div className="social-icon">
                                  <a href="#"><i className="ti-facebook"></i></a>
                                  <a href="#"><i className="ti-twitter"></i></a>
                                  <a href="#"><i className="ti-instagram"></i></a>
                                  <a href="#"><i className="ti-pinterest"></i></a>
                              </div>*/}
                          </div>
                      </div>
                      <div className="col-md-4 offset-md-1 mb-30">
                          <div className="item usful-links">
                              <div className="fothead">
                                  <h6>Nos services</h6>
                              </div>
                              <ul>
                                  <li><a href="/" data-scroll-nav="2">Nos services</a></li>
                                  <li><a href="/" data-scroll-nav="3">L'architecte</a></li>
                                  <li><a href="/" data-scroll-nav="3">L'installateur</a></li>
                                  <li><a href="/" data-scroll-nav="4">Produits et matériaux</a></li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-md-4 mb-30">
                          <div className="item fotcont">
                              <div className="fothead">
                                  <h6>Parlons-en</h6>
                              </div>
                              <p>Vous avez un projet en tête ?</p>
                              <p>Bâtissons ensemble quelque chose de grand</p>
                              <p>+33 (0) 7 50 09 58 44</p>
                              <p>faz@prescription-box.com</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="sub-footer">
                  <div className="container">
                      <div className="row">
                          <div className="col-md-8">
                              <div className="text-left">
                                  <p>© 2024, Prescription Matériaux. All right reserved.</p>
                              </div>
                          </div>
                          <div className="col-md-4">
                              <div className="text-right-left">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
      </>
  )
}

export default Footer;
